import React, { useState, useEffect } from 'react'
import { AdSlot } from 'react-dfp'
import Typography from '@lib/ui/core/Typography'


const DfpAdSlot = ({ adUnit, sizes }) => {

  const [displayText, setDisplayText] = useState(false)

  return (
    <>
      <AdSlot adUnit={adUnit} sizes={sizes} 
      onSlotRender={(eventData) => {
        if(eventData.event.isEmpty)
          setDisplayText(false)
        else
          setDisplayText(true)
      }} />
      {displayText && (
        <Typography variant="body1" align="center" style={{color: '#636363', fontSize: '0.625rem'}}>
          Sponsored
        </Typography>
      )}
    </>
  )
}

export default DfpAdSlot
